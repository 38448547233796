<template>
    <BasicCard :loading="loading" title="DID Pricing">
        <p class="title text--primary mt-1">
            <Currency :value="data.recurring_cost" add-gst hide-tooltip /> per
            month
        </p>

        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Activation Cost</span>
            </v-col>
            <v-col cols="7">
                <Currency :value="data.activation_cost" add-gst />
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Recurring Cost</span>
            </v-col>
            <v-col cols="7">
                <Currency :value="data.recurring_cost" add-gst />
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Free of Charge</span>
            </v-col>
            <v-col cols="7">
                {{ data.free_of_charge }}
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip
                :href="
                    'https://admin.breezeconnect.com.au:8558/ui/did_inventory/did_number/list;number=' +
                    data.number
                "
                text="PortaOne"
                icon="mdi-open-in-new"
                color="primary"
                tooltip="View DID in PortaOne Admin"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import Currency from '../../pieces/Currency';
export default {
    name: 'DIDPricing',
    components: { Currency, ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
